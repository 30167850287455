<template>
    <div>
        <!-- Filters -->
        <b-card no-body>
            <b-card-header class="pb-50">
                <h5>Register Buyer</h5>
            </b-card-header>
            <b-card-body>
                <validation-observer ref="dataForm" #default="{ invalid }">
                    <b-form ref="form" @submit.stop.prevent="handleCreate">
                        <b-row>
                            <b-col
                                cols="12"
                                md="4"
                                class="mb-md-0 mb-2"
                            >
                                <b-form-group label-for="name" label="Buyer Name">
                                    <validation-provider #default="{ errors }" name="Buyer Name" rules="">
                                        <b-form-input
                                            id="name"
                                            name="name"
                                            size="sm"
                                            placeholder="Name"
                                            v-model="buyer.name"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.name">{{ serverErrors.name[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col
                                cols="12"
                                md="2"
                                class="mb-md-0 mb-2"
                            >
                                <b-form-group label-for="abbreviation" label="Abbreviation">
                                    <validation-provider #default="{ errors }" name="Abbreviation" rules="">
                                        <b-form-input
                                            id="abbreviation"
                                            name="abbreviation"
                                            size="sm"
                                            v-model="buyer.abbreviation"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.abbreviation">{{ serverErrors.abbreviation[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <b-col
                                cols="12"
                                md="2"
                                class="mb-md-0 mb-2 pt-2"
                            >
                                <b-button
                                    size="sm"
                                    variant="primary"
                                    @click="submit()"
                                    :disabled="saving"
                                >
                                    <b-spinner small class="mr-1" label="Small Spinner" v-if="saving" />
                                    <span v-if="!saving">Save</span>
                                    <span v-if="saving">Saving...</span>
                                </b-button>
                            </b-col>
                            <b-col
                                cols="12"
                                md="2"
                                class="mb-md-0 mb-2 pt-2"
                            >
                                <b-button
                                    size="sm"
                                    @click="clear()"
                                    variant="outline-secondary"
                                >
                                    Clear
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-form>
                </validation-observer>
            </b-card-body>
            <!-- {{ buyer }} -->
        </b-card>
        <!-- ./Filters -->

        <b-card no-body class="mb-0">
            <div class="m-2">
                <!-- Table Top -->
                <b-row>
                    <!-- Per Page -->
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label>Show</label>
                        <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions" :clearable="false"
                            class="per-page-selector d-inline-block mx-50 select-size-sm" />
                        <label>entries</label>
                    </b-col>
                    <!-- ./Per Page -->

                    <!-- Search & Button -->
                    <b-col cols="12" md="6">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Search..."
                                size="sm" />
                        </div>
                    </b-col>
                    <!-- ./Search & Button-->
                </b-row>
                <!-- ./Table Top -->
            </div>

            <!-- Table -->
            <b-table
                striped
                hover
                small
                ref="records"
                class="position-relative"
                :items="fetch"
                responsive
                :fields="columns"
                primary-key="id"
                :sort-by.sync="sortBy"
                show-empty
                empty-text="No records found"
                :sort-desc.sync="isSortDirDesc"
            >
                <!-- Column: Actions -->
                <template #cell(actions)="data">
                    <b-dropdown
                        variant="link"
                        no-caret
                        :right="$store.state.appConfig.isRTL"
                    >

                        <template #button-content>
                        <feather-icon
                            icon="MoreVerticalIcon"
                            size="16"
                            class="align-middle text-body"
                        />
                        </template>

                        <b-dropdown-item @click="invokeUpdateForm(data.item)">
                            <feather-icon icon="EditIcon" />
                            <span class="align-middle ml-50">Edit</span>
                        </b-dropdown-item>
        
                        <b-dropdown-item @click="remove(data.item.id)">
                            <feather-icon icon="TrashIcon" />
                            <span class="align-middle ml-50">Delete</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </template>
            </b-table>

            <!-- Table Footer -->
            <div class="mx-2 mb-2">
                <b-row>
                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start"
                    >
                        <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }} entries</span>
                    </b-col>
                    <!-- Pagination -->
                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-end"
                    >
                        <b-pagination
                            v-model="currentPage"
                            :total-rows="totalRecords"
                            :per-page="perPage"
                            first-number
                            last-number
                            class="mb-0 mt-1 mt-sm-0"
                            prev-class="prev-item"
                            next-class="next-item"
                        >
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div>
        </b-card>
    </div>
</template>

<script>
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import {
        BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
        BForm, BFormGroup,
        BButton, BFormSelect, BFormSelectOption, BFormInput, BDropdown, BDropdownItem,
        BSpinner,
    } from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import { required } from '@validations'
    import store from '@/store'
    import { ref, onUnmounted, onMounted } from '@vue/composition-api'
    import buyersStoreModule from '@/views/cromis/buyers/buyersStoreModule'
    import useBuyersList from '@/views/cromis/buyers/useBuyersList'

    export default {
        props: {},
        components: {
            BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
            BForm, BFormGroup,
            BButton, BFormSelect, BFormSelectOption, BFormInput, vSelect, BDropdown, BDropdownItem,
            BSpinner,
            ValidationObserver, ValidationProvider,
        },
        directives: {},

        setup(props, context) {
            const serverErrors = ref(null)
            const saving = ref(false)
            const dataForm = ref(null)

            const buyer = ref({
                id: null,
                name: null,
                abbreviation: null
            })
            

            const CROMIS_STORE_MODULE_NAME = 'cromis-buyer'

            // Register module
            if (!store.hasModule(CROMIS_STORE_MODULE_NAME)) store.registerModule(CROMIS_STORE_MODULE_NAME, buyersStoreModule)

            // UnRegister on leave
            onUnmounted(() => {
                if (store.hasModule(CROMIS_STORE_MODULE_NAME)) store.unregisterModule(CROMIS_STORE_MODULE_NAME)
            })

            const {
                fetch,
                columns,
                perPage,
                currentPage,
                totalRecords,
                from,
                to,
                meta,
                perPageOptions,
                searchQuery,
                sortBy,
                isSortDirDesc,
                records,
                refetch,
            } = useBuyersList()

            const clear = () => {
                buyer.value = { id: null, name: null, abbreviation: null }
            }

            const invokeUpdateForm = (item) => {
                buyer.value = { id: item.id, name: item.name, abbreviation: item.abbreviation }
            }

            const isFormValid = async () => {
                let isValid = false 
                await dataForm.value.validate().then(success => {
                    isValid = success
                })

                return isValid
            }

            const submit = (bvModalEvt) => {
                serverErrors.value = null
                // Handle form submit
                if (buyer.value.id === null || buyer.value.id === 0)
                    handleCreate()
                else
                    handleUpdate(buyer.value)
            }

            const handleCreate = async () => {
                saving.value = true
                const valid = await isFormValid()

                if(!valid){
                    saving.value = false
                    return
                }

                
                await store.dispatch('cromis-buyer/create', buyer.value)
                    .then(response => {
                        refetch()
                        saving.value = false

                        clear()
                        context.root.$swal({
                            icon: 'success',
                            text: `Buyer was registered successfully!`,
                            showConfirmButton: true,
                            timer: 3000,
                            customClass: {
                                confirmButton: 'btn btn-primary',
                            },
                            buttonsStyling: false,
                        })
                    })
                    .catch(error => {
                        saving.value = false
                        if(error.response.status === 422){
                            serverErrors.value = error.response.data.errors
                        }
                        else{
                            context.root.$swal({
                                icon: 'error',
                                title: 'Server Error',
                                text: 'Something went wrong. See tech support',
                                showConfirmButton: true,
                                customClass: {
                                    confirmButton: 'btn btn-danger',
                                },
                                buttonsStyling: false,
                            })
                        }
                    })
            }

            const handleUpdate = async (item) => {
                saving.value = true
                const valid = await isFormValid()

                if(!valid){
                    saving.value = false
                    return
                }

                await store.dispatch('cromis-buyer/update', { id: item.id, data: item })
                    .then(response => {
                        refetch()
                        saving.value = false

                        clear()
                        context.root.$swal({
                            icon: 'success',
                            text: `Changes to buyer ${response.data.name} has been saved successfully!`,
                            showConfirmButton: true,
                            timer: 3000,
                            customClass: {
                                confirmButton: 'btn btn-success',
                            },
                            buttonsStyling: false,
                        })
                    })
                    .catch(error => {
                        saving.value = false
                        if(error.response.status === 422){
                            serverErrors.value = error.response.data.errors
                        }
                        else{
                            context.root.$swal({
                                icon: 'error',
                                title: 'Server Error',
                                text: 'Something went wrong. See tech support',
                                timer: 3000,
                                showConfirmButton: true,
                                customClass: {
                                    confirmButton: 'btn btn-danger',
                                },
                                buttonsStyling: false,
                            })
                        }
                    })
            }

            const remove = async (id) => {
                await store.dispatch('cromis-buyer/remove', id)
                            .then(response => {
                                refetch()

                                context.root.$swal({
                                    icon: 'info',
                                    title: 'System Info',
                                    text: 'Buyer has been deleted successfully',
                                    showConfirmButton: true,
                                    customClass: {
                                        confirmButton: 'btn btn-info',
                                    },
                                    buttonsStyling: true,
                                })
                            })
                            .catch(error => {
                                context.root.$swal({
                                    icon: 'error',
                                    title: 'Server Error',
                                    text: 'Something went wrong. See tech support',
                                    showConfirmButton: true,
                                    customClass: {
                                        confirmButton: 'btn btn-danger',
                                    },
                                    buttonsStyling: true,
                                })
                            })
            }

            return {
                dataForm,
                serverErrors,
                saving,
                buyer,

                fetch,
                columns,
                perPage,
                currentPage,
                totalRecords,
                from,
                to,
                meta,
                perPageOptions,
                searchQuery,
                sortBy,
                isSortDirDesc,
                records,
                refetch,

                submit,
                remove,
                clear,
                invokeUpdateForm,
            }
        }
    }
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
</style>